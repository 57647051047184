@import '../../styles/helpers/index.scss';

.page-footer {
  color: #fff;

  a {
    color: #fff;
  }

  .container {
    &__inner {
      padding: 100px 0 30px;

      @include align-center;
      flex-direction: column;
      background-color: map-get($colors, 'red');
      width: 100%;
    }
  }

  &__logo {
    height: 100px;
    margin: 0 0 35px;
  }

  .phone {
    font-weight: 700;
    font-size: 40px;
    line-height: 155%;
    margin: 0 0 35px;

    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 15px;
    }
  }

  .email {
    font-weight: 400;
    font-size: 24px;
    line-height: 155%;

    @media screen and (max-width: $tablet-sm) {
      font-size: 18px;
    }
  }

  .links {
    margin: 35px 0 0;
    display: flex;
    gap: 15px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      text-align: center;
    }

    a {
      &:hover {
        text-decoration: underline !important;
      }

      @media screen and (max-width: $tablet-sm) {
        font-size: 14px;
      }
    }
  }

  &__underline {
    margin: 50px 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    width: 80%;

    @media screen and (max-width: $tablet-sm) {
      width: 100%;
    }

    .container {
      padding: 30px 0 0;
      border-top: 1px solid #fff;

      @include align-center;
      flex-direction: column;

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
        padding: 30px 0 60px;
      }
    }

    a {
      margin: 15px 0 0;

      &:hover {
        strong {
          text-decoration: underline;
        }
      }

      &:last-of-type {
        strong {
          font-weight: bold;
        }
      }
    }
  }
}
