@import '../../styles/helpers/index.scss';

.hero {
  .container__inner {
    @include space-between;
    padding-right: 0;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 0 0 30px;
    }

    & > div {
      width: 100%;
      max-width: 50%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }
    }
  }

  &__image {
    min-height: 650px;

    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__content {
    padding: 95px 0 0 40px;
    color: map-get($colors, 'red');

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 15px 0;
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 120%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 32px;
      }
    }

    p {
      font-size: 40px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 20px;
        margin: 5px 0 0;
      }
    }

    &--links {
      margin: 45px 0 0;
      @include align-center;
      gap: 25px;

      @media screen and (max-width: $tablet-sm) {
        gap: 15px;
        margin: 30px 0 0;
      }

      span {
        font-weight: 600;
        font-size: 24px;
        line-height: 200%;
        color: map-get($colors, 'text');

        @media screen and (max-width: $tablet-sm) {
          font-size: 14px;
        }

        a {
          color: map-get($colors, 'text');

          @media screen and (max-width: $tablet-sm) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
