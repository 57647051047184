@import '../../styles/helpers/index.scss';

.areas {
  .container {
    &__inner {
      padding: 55px 0 55px 40px;
      border-top: 1px solid map-get($colors, 'red');
      border-bottom: 1px solid map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
        padding: 55px 0;
      }
    }
  }

  ul {
    padding: 55px 0 55px 40px;
    border-left: 1px solid map-get($colors, 'red');
    border-right: 1px solid map-get($colors, 'red');
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 2;
      padding: 55px 15px;
    }

    li {
      font-size: 18px;
      line-height: 200%;
      color: map-get($colors, 'text');
      position: relative;
      text-indent: 25px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 14px;
      }

      &::before {
        content: '';
        background-image: url('../../static/arrow_list.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 8px;
        left: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 12px;
      }
    }
  }
}
