@import '../../styles/helpers/index.scss';

.call-to-action {
  .container {
    &__inner {
      background-image: url('../../static/cta_background.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      padding: 150px 40px;
      border-left: none;

      @media screen and (max-width: $tablet-sm) {
        padding: 70px 15px;
      }
    }
  }

  .section-title {
    color: #fff;
  }

  .links {
    margin: 65px 0 0;
    @include align-center;
    gap: 25px;
    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      gap: 15px;
    }

    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 200%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 14px;
      }

      a {
        color: #fff;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
