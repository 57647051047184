@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  background-color: #fff;
  border-bottom: 1px solid map-get($colors, 'red');

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);
  }

  .mobile-btn {
    @media screen and (max-width: $tablet-sm) {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: map-get($colors, 'red');

      a {
        padding: 5px 0;
        color: #fff;
        &::before {
          display: none;
        }
      }
    }
  }

  .container {
    &__inner {
      @include align-center;
      justify-content: space-between;
      padding: 0 0 0 40px;

      @media screen and (max-width: $tablet-sm) {
        padding: 0 15px;
      }
    }
  }

  &__links {
    @include align-center;
    gap: 55px;

    @media screen and (max-width: $tablet-sm) {
      // display: none;
      flex-direction: column;
      gap: 15px;
      a:first-of-type {
        display: none;
      }
    }
  }

  &__logo {
    img {
      @media screen and (max-width: $tablet-sm) {
        height: 60px;
      }
    }
  }

  &__phone {
    position: relative;
    @include align-center;
    color: map-get($colors, 'text');

    font-weight: 700;
    font-size: 24px;

    &::before {
      content: '';

      background-image: url('../../static/phone_icon.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      width: 20px;
      height: 20px;

      position: absolute;
      top: 50%;
      left: -35px;
      transform: translateY(-50%);
    }
  }

  &__mobile-phone {
    display: none;

    background: #eee;
    text-align: center;
    padding: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    a {
      color: map-get($colors, 'text');
    }

    @media screen and (max-width: $tablet-sm) {
      display: block;
    }
  }

  &__mobile-btn {
    display: none;

    a {
      position: relative;
      display: inline-block;

      width: 42px;
      height: 42px;

      border-radius: 50%;
      border: 1px solid map-get($colors, 'red');

      &::before {
        content: '';

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        width: 22px;
        height: 22px;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }

    &--phone {
      margin: 0 10px 0 0;
      &::before {
        background-image: url('../../static/phone_icon.svg');
      }
    }

    &--booking::before {
      background-image: url('../../static/calendar_icon.svg');
    }

    @media screen and (max-width: $tablet-sm) {
      @include align-center;
    }
  }
}
