@import '../../styles/helpers/index.scss';

.services {
  .container {
    &__inner {
      padding: 55px 0 55px 40px;
      border-top: 1px solid map-get($colors, 'red');
      border-bottom: 1px solid map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        padding: 55px 30px 75px;
        text-align: center;
      }
    }
  }

  &__list {
    border-left: 1px solid map-get($colors, 'red');

    &--card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 80px 40px 65px;
      background-color: #fff;

      border-right: 1px solid map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        padding: 30px;
      }

      &:hover {
        .arrow {
          transform: rotate(180deg);
        }
      }

      .icon {
        height: 80px;
        @include align-center;
      }

      h4 {
        height: 60px;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        margin: 45px 0 30px;
        color: map-get($colors, 'red');
      }

      p {
        height: 200px;
        font-weight: 400;
        font-size: 16px;
        line-height: 155%;
        color: map-get($colors, 'text');
        margin: 0 0 60px;

        @media screen and (max-width: $tablet-sm) {
          margin: 0 0 30px;
        }
      }

      .arrow {
        width: 40px;
        height: 40px;
        object-fit: contain;
        transition: all 0.3s ease;

        @media screen and (max-width: $tablet-sm) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  // .ticker {
  //   background-color: map-get($colors, 'red');
  //   height: 100px !important;
  //   line-height: 100px !important;

  //   color: #fff;
  //   font-weight: 400;
  //   font-size: 24px;
  //   line-height: 120%;

  //   span {
  //     font-weight: 600;
  //     display: block;
  //   }

  //   &__element {
  //     @include align-center;
  //   }
  // }

  // .slide-text {
  //   display: block;
  //   width: 100%;
  //   white-space: nowrap;
  //   overflow: hidden;

  //   span {
  //     display: flex;
  //     justify-content: space-around;
  //     // display: inline-block;
  //     padding-left: 100%;
  //     // -webkit-animation: scroll 5s infinite linear;
  //     // -moz-animation: scroll 5s infinite linear;
  //     animation: scroll 5s infinite linear;
  //   }
  // }
}
