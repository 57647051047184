@import '../../styles/helpers/index.scss';

.reviews {
  .container {
    &__inner {
      padding: 55px 0 55px 40px;
      border-top: 1px solid map-get($colors, 'red');
      border-bottom: 1px solid map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        padding: 55px 30px 75px;
        text-align: center;
      }
    }
  }

  &__list {
    border-left: 1px solid map-get($colors, 'red');

    &--card {
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 60px 40px;
      background-color: #fff;

      border-right: 1px solid map-get($colors, 'red');

      @media screen and (max-width: $tablet-sm) {
        height: auto;
        padding: 30px;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .icon {
          @include align-center;
          justify-content: center;

          height: 80px;
          width: 80px;
          border-radius: 50%;
          border: 1px solid map-get($colors, 'red');

          @media screen and (max-width: $tablet-sm) {
            height: 60px;
            width: 60px;

            img {
              height: 60%;
            }
          }
        }

        .stars {
          @include align-center;
          gap: 10px;
        }
      }

      &__info {
        margin: 45px 0 0;
        @include space-between;
        color: map-get($colors, 'red');

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }

        .name {
          max-width: 120px;
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
        }

        .date {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
      }

      p {
        margin: 30px 0 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: map-get($colors, 'text');
        height: 270px;

        overflow: scroll;
      }
    }
  }
}
