@import '../../styles/helpers/index.scss';

.why-we {
  .container {
    &__inner {
      border-top: 1px solid map-get($colors, 'red');
      padding: 75px 40px;

      @media screen and (max-width: $tablet-sm) {
        padding: 50px 30px;
      }
    }
  }

  .section-title {
    @media screen and (max-width: $tablet-sm) {
      text-align: center;
    }
  }

  &__grid {
    margin: 70px 0 0;
    @include grid($columns: repeat(4, 1fr));

    & > div {
      padding: 0 40px 0 0;

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
        padding: 0 0 30px;

        .icon {
          img {
            margin: 0 auto;
          }
        }
      }

      &:not(:last-of-type) {
        border-right: 1px solid map-get($colors, 'red');

        @media screen and (max-width: $tablet-sm) {
          border: none;
          border-bottom: 1px solid map-get($colors, 'red');
        }
      }

      h4 {
        margin: 30px 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: map-get($colors, 'red');
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 155%;
        color: map-get($colors, 'text');
      }
    }
  }
}
