@import '../../styles/helpers/index.scss';

.social-media {
  position: absolute;
  z-index: 999;
  top: 80vh;
  left: 15px;

  max-width: unset !important;
  width: 60px !important;

  @include align-center;
  row-gap: 15px;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: $tablet-sm) {
    display: none;
  }

  a {
    @include scale;
  }
}
